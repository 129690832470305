import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import ContactPerson from "../components/contactPerson";
import { deleteCookies } from "../components/cookieConsent";

const Datenschutz = () => {
  const images = useStaticQuery(graphql`
    {
      volker: file(relativePath: { eq: "profiles/volker-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="nav.privacy.link">
      <Seo
        title="Monday Consulting GmbH – Datenschutzerklärung"
        description="Alle Informationen zum Thema Datenschutz."
        keywords="Datenschutz, Disclaimer, externe Links, Google Analytics, Haftung, Urheberrecht"
      />
      <div className="parallax-translate bg-shape-diamond" />
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12">
            <h1 className="text-size-2 text-size-md-1">Datenschutzerklärung</h1>
          </div>
        </div>
      </div>
      <div className="container mt-4 mb-4 reveal">
        <div className="row">
          <div className="col-12">
            <p>
              Wir freuen uns über Ihren Besuch auf unserer Webseite
              www.monday-consulting.com und Ihr Interesse an unserem Unternehmen
              und unseren Angeboten. Für externe Links zu fremden Inhalten
              übernehmen wir trotz sorgfältiger inhaltlicher Kontrolle keine
              Haftung, da wir die Übermittlung dieser Information nicht
              veranlasst, den Adressaten der übermittelten Information und die
              übermittelten Informationen selbst nicht ausgewählt oder verändert
              haben.
            </p>
            <p>
              Der Schutz Ihrer personenbezogenen Daten bei der Erhebung,
              Verarbeitung und Nutzung anlässlich Ihres Besuchs auf unseren
              Internetseiten ist uns ein wichtiges Anliegen und erfolgt im
              Rahmen der gesetzlichen Vorschriften, über die Sie sich z.B. unter
              www.bfd.bund.de informieren können.
            </p>
            <p>
              Im Folgenden erläutern wir Ihnen, welche Informationen wir während
              Ihres Besuchs auf unseren Webseiten erfassen und wie diese genutzt
              werden:
            </p>

            <h2 className="h4">
              1. Erhebung und Speicherung personenbezogener Daten sowie Art und
              Zweck von deren Verwendung
            </h2>
            <p>
              Bei jedem Zugriff eines Kunden (oder sonstigen Besuchers) auf
              unserer Webseite werden durch den auf Ihrem Endgerät (Computer,
              Laptop, Tablet, Smartphone, etc.) zum Einsatz kommenden
              Internet-Browser automatisch Informationen an den Server unserer
              Webseite gesendet. Diese Informationen werden temporär in einem
              sog. Logfile (Protokolldatei) gespeichert.
            </p>
            <p>
              Folgende Daten werden dabei ohne Ihr Zutun erfasst und bis zur
              automatisierten Löschung gespeichert:
            </p>
            <ul>
              <li>
                IP-Adresse des anfragenden Rechners, sowie Geräte-ID oder
                individuelle Geräte-Kennung und Gerätetyp,
              </li>
              <li>
                Name der abgerufenen Datei und übertragene Datenmenge, sowie
                Datum und Uhrzeit des Abrufs,
              </li>
              <li>Meldung über erfolgreichen Abruf,</li>
              <li>anfragende Domain,</li>
              <li>
                Beschreibung des Typs des verwendeten Internet-Browsers und ggf.
                des Betriebssystems Ihres Endgeräts sowie der Name Ihres
                Access-Providers,
              </li>
              <li>
                Ihre Browser-Verlaufsdaten sowie Ihre standardmäßigen
                Weblog-Informationen,
              </li>
              <li>
                Standortdaten, einschließlich Standortdaten von Ihrem
                Mobilgerät. Bitte beachten Sie, dass Sie bei den meisten
                Mobilgeräten die Verwendung von Standortservices im
                Einstellungsmenü des Mobilgeräts steuern oder deaktivieren
                können.
              </li>
            </ul>
            <p>
              Unser berechtigtes Interesse gem. Art. 6 Abs. 1 S. 1 lit. f DSGVO
              zur Erhebung der Daten beruht auf den folgenden Zwecken:
              Gewährleistung eines reibungslosen Verbindungsaufbaus und einer
              komfortablen Nutzung der Webseite, Auswertung der Systemsicherheit
              und -stabilität sowie zu weiteren administrativen Zwecken.
            </p>
            <p>
              In keinem Fall verwenden wir die erhobenen Daten zu dem Zweck,
              Rückschlüsse auf Ihre Person zu ziehen.
            </p>

            <h2 className="h4">2. Bewerberformular</h2>
            <p>
              Wenn Sie unser Bewerbungsformular nutzen, um uns Ihr Interesse an
              einer möglichen Zusammenarbeit mitzuteilen, werden Ihre Angaben
              aus dem Bewerbungsformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht
              ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten
              erfolgt auf Grundlage Ihrer Einwilligung Art. 6 Abs. 1 lit. a
              DSGVO. Die von Ihnen im Kontaktformular eingegebenen Daten
              verbleiben bei uns maximal 3 Monate, sofern eine Zusammenarbeit
              nicht zustande kommt.
            </p>

            <h3 className="h5">Umgang mit Bewerberdaten</h3>
            <p>
              Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
              per E-Mail oder via Online-Bewerbungsformular). Im Folgenden
              informieren wir Sie über Umfang, Zweck und Verwendung Ihrer im
              Rahmen des Bewerbungsprozesses erhobenen personenbezogenen Daten.
              Wir versichern, dass die Erhebung, Verarbeitung und Nutzung Ihrer
              Daten in Übereinstimmung mit geltendem Datenschutzrecht und allen
              weiteren gesetzlichen Bestimmungen erfolgt und Ihre Daten streng
              vertraulich behandelt werden.
            </p>

            <h3 className="h5">Umfang und Zweck der Datenerhebung</h3>
            <p>
              Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
              damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
              Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
              Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
              Begründung eines Beschäftigungsverhältnisses erforderlich ist.
              Rechtsgrundlage hierfür ist § 26 BDSG nach deutschem Recht
              (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit.
              b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
              Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
              Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen
              Daten werden innerhalb unseres Unternehmens ausschließlich an
              Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung
              beteiligt sind. Sofern die Bewerbung erfolgreich ist, werden die
              von Ihnen eingereichten Daten auf Grundlage von § 26 BDSG und Art.
              6 Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des
              Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
              gespeichert.
            </p>

            <h3 className="h5">Aufbewahrungsdauer der Daten</h3>
            <p>
              Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
              Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten
              wir uns das Recht vor, die von Ihnen übermittelten Daten auf
              Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f
              DSGVO) bis zu 3 Monate ab der Beendigung des Bewerbungsverfahrens
              (Ablehnung oder Zurückziehung der Bewerbung) bei uns
              aufzubewahren. Anschließend werden die Daten gelöscht und die
              physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient
              insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern
              ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist
              erforderlich sein werden (z. B. aufgrund eines drohenden oder
              anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn
              der Zweck für die weitergehende Aufbewahrung entfällt. Eine
              längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
              entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt
              haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung
              entgegenstehen.
            </p>

            <h2 className="h4">3. Weitergabe von personenbezogenen Daten</h2>
            <p>
              Eine Übermittlung Ihrer Daten an Dritte zu anderen als den im
              Folgenden aufgeführten Zwecken findet nicht statt.
            </p>
            <p>Wir geben Ihre Daten nur an Dritte weiter, wenn:</p>
            <ul>
              <li>
                Sie eine ausdrückliche Einwilligung dazu erteilt haben nach
                (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
              </li>
              <li>
                dies für die Abwicklung von Vertragsverhältnissen mit Ihnen
                erforderlich ist (Art. 6 Abs. 1 lit. b DSGVO),
              </li>
              <li>
                eine gesetzliche Verpflichtung zur Weitergabe besteht (Art.6
                Abs.1 lit. c DSGVO),
              </li>
              <li>
                die Weitergabe zur Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme
                besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an
                der Nichtweitergabe Ihrer Daten haben (Art. 6 Abs. 1 S. 1 lit. f
                DSGVO).
              </li>
            </ul>
            <p>
              In diesen Fällen beschränkt sich der Umfang der übermittelten
              Daten jedoch nur auf das erforderliche Minimum.
            </p>
            <p>
              Unsere Datenschutzbestimmungen stehen im Einklang mit den
              geltenden datenschutzrechtlichen Bestimmungen und die Daten werden
              nur in der Bundesrepublik Deutschland / Europäischen Union
              verarbeitet. Eine Übermittlung in Drittländer findet nicht statt
              und ist nicht beabsichtigt.
            </p>

            <h2 className="h4">4. Betroffenenrechte</h2>
            <p>
              Auf Anfrage werden wir Sie gern informieren, ob und welche
              personenbezogenen Daten zu Ihrer Person gespeichert sind (Art. 15
              DSGVO), insbesondere über die Verarbeitungszwecke, die Kategorie
              der personenbezogenen Daten, die Kategorien von Empfängern,
              gegenüber denen Ihre Daten offengelegt wurden oder werden, die
              geplante Speicherdauer, das Bestehen eines Rechts auf
              Berichtigung, Löschung, Einschränkung der Verarbeitung oder
              Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft
              ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über
              das Bestehen einer automatisierten Entscheidungsfindung
              einschließlich Profiling.
            </p>
            <p>
              Ihnen steht zudem das Recht zu, etwaig unrichtig erhobene
              personenbezogene Daten berichtigen oder unvollständig erhobene
              Daten vervollständigen zu lassen (Art. 16 DSGVO).
            </p>
            <p>
              Ferner haben Sie das Recht, von uns die Einschränkung der
              Verarbeitung Ihrer Daten zu verlangen, sofern die gesetzlichen
              Voraussetzungen hierfür vorliegen (Art. 18 DSGVO).
            </p>
            <p>
              Sie haben das Recht, die Sie betreffenden personenbezogenen Daten
              in einem strukturierten, gängigen und maschinenlesbaren Format zu
              erhalten oder die Übermittlung an einen anderen Verantwortlichen
              zu verlangen (Art. 20 DSGVO).
            </p>
            <p>
              Darüber hinaus steht Ihnen das sogenannte „Recht auf
              Vergessenwerden“ zu, d.h. Sie können von uns die Löschung Ihrer
              personenbezogenen Daten verlangen, sofern hierfür die gesetzlichen
              Voraussetzungen vorliegen (Art. 17 DSGVO).
            </p>
            <p>
              Unabhängig davon werden Ihre personenbezogenen Daten automatisch
              von uns gelöscht, wenn der Zweck der Datenerhebung weggefallen
              oder die Datenverarbeitung unrechtmäßig erfolgt ist.
            </p>
            <p>
              Gemäß Art. 7 Abs. 3 DSGVO haben Sie das Recht Ihre einmal erteilte
              Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur
              Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung
              beruhte, für die Zukunft nicht mehr fortführen dürfen.
            </p>
            <p>
              Sie haben zudem das Recht, jederzeit gegen die Verarbeitung Ihrer
              personenbezogenen Daten Widerspruch zu erheben, sofern ein
              Widerspruchsrecht gesetzlich vorgesehen ist. Im Falle eines
              wirksamen Widerrufs werden Ihre personenbezogenen Daten ebenfalls
              automatisch durch uns gelöscht (Art. 21 DSGVO).
            </p>
            <p>
              Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
              machen, genügt eine E-Mail an:
              <a href="mailto:datenschutz@monday-consulting.com">
                datenschutz@monday-consulting.com
              </a>
              .
            </p>
            <p>
              Bei Verstößen gegen die datenschutzrechtlichen Vorschriften haben
              Sie gem. Art. 77 DSGVO die Möglichkeit, Beschwerde bei einer
              Aufsichtsbehörde zu erheben.
            </p>

            <h2 className="h4">5. Dauer der Datenspeicherung</h2>
            <p>
              Die erhobenen Daten werden solange bei uns gespeichert, wie dies
              für die Durchführung der mit uns eingegangen Verträge erforderlich
              ist oder Sie Ihr Recht auf Löschung oder Ihr Recht auf
              Datenübertragung auf ein anderes Unternehmen nicht ausgeübt haben.
            </p>

            <h2 className="h4">6. Cookies</h2>
            <button
              className="btn ml-2 my-1 my-md-2"
              onClick={() => {
                deleteCookies();
                window.location.reload();
              }}
            >
              Cookie Einstellungen
            </button>
            <p>
              Wir setzen auf unserer Webseite Cookies ein. Hierbei handelt es
              sich um kleine Text-Dateien, die Ihr Browser automatisch erstellt
              und die auf Ihrem Endgerät gespeichert werden, wenn Sie unsere
              Webseite besuchen. In dem Cookie werden Informationen abgelegt,
              die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
              Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir unmittelbar
              Kenntnis von Ihrer Identität erhalten. Der Einsatz von Cookies
              dient zunächst dazu, die Nutzung unseres Angebots für Sie
              angenehmer zu gestalten:
            </p>
            <p>
              Zum anderen setzen wir Cookies ein, um die Nutzung unserer
              Webseite statistisch zu erfassen und auszuwerten und unser
              Angebotes für Sie zu optimieren. Diese Cookies ermöglichen es uns,
              bei einem erneuten Besuch unserer Webseite automatisch zu
              erkennen, dass Sie bereits bei uns waren. Diese Cookies werden
              nach einer jeweils definierten Zeit automatisch gelöscht. Die
              durch Cookies verarbeiteten Daten sind für die genannten Zwecke
              zur Wahrung unserer berechtigten Interessen sowie der Dritter nach
              Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich. Die meisten Browser
              akzeptieren Cookies automatisch. Wenn Sie es nicht wünschen, dass
              wir Informationen über Ihren Computer wiedererkennen, stellen Sie
              Ihren Internetbrowser bitte so ein, dass er Cookies von Ihrer
              Computerfestplatte löscht, alle Cookies blockiert oder Sie warnt,
              bevor ein Cookie gespeichert wird. Wie Sie Cookies löschen oder
              blockieren können, erfahren Sie im Hilfe- und Support-Bereich
              Ihres Internet-Browsers. Dort finden Sie Anweisungen zur Suche der
              Datei oder des Verzeichnisses, in dem Cookies gespeichert werden.
              Bitte beachten Sie in jedem Fall, dass die vollständige
              Deaktivierung von Cookies dazu führen kann, dass Sie nicht alle
              Funktionen unserer Webseite nutzen können.
            </p>
            <p>
              Bei der Bereitstellung unseres Internetangebots werden
              Java-Applets und Java-Script verwendet. Falls Sie sich aus
              Sicherheitsgründen diese Hilfsprogramme bzw. aktiven Inhalte nicht
              nutzbar machen wollen, sollten Sie die entsprechende Einstellung
              Ihres Browsers deaktivieren.
            </p>

            <h2 className="h4">7. Online-Marketing / Analyse-Tools</h2>
            <p>
              Die von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage
              des Art. 6 Abs. 1 S. 1 lit. f DSGVO, § 15 Abs. 3 TMG durchgeführt.
              Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine
              bedarfsgerechte Gestaltung und die fortlaufende Optimierung
              unserer Webseite sicherstellen. Zum anderen setzen wir die
              Tracking-Maßnahmen ein, um die Nutzung unserer Webseite
              statistisch zu erfassen und zum Zwecke Auswertung und Optimierung
              unseres Internet-Auftrittes. Diese Interessen sind als berechtigt
              im Sinne der vorgenannten Vorschrift anzusehen.
            </p>
            <h3 className="h5">Google Analytics</h3>
            <p>
              Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
              Optimierung unserer Webseiten nutzen wir Google Analytics, einen
              Webanalysedienst der Google Inc. (www.google.de/intl/de/about/)
              (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im
              Folgenden „Google“). In diesem Zusammenhang werden
              pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe auch
              unter Ziff. 5) gesetzt. Die durch den Cookie erzeugten
              Informationen über Ihre Benutzung dieser Webseite, wie
              Browser-Typ/-Version, verwendetes Betriebssystem, Referrer-URL
              (die zuvor besuchte Seite), Hostname des zugreifenden Rechners
              (IP-Adresse) und die Uhrzeit der Serveranfrage werden an einen
              Server von Google in den USA übertragen und dort gespeichert. Die
              Informationen werden verwendet, um die Nutzung der Webseite
              auszuwerten, um Reports über die Webseitenaktivitäten
              zusammenzustellen und um weitere mit der Webseitennutzung und der
              Internetnutzung verbundene Dienstleistungen zu Zwecken der
              Marktforschung und bedarfsgerechten Gestaltung dieser Webseiten zu
              erbringen. In diesen Zwecken liegt auch unser berechtigtes
              Interesse an der Datenverarbeitung. Die Rechtsgrundlage für den
              Einsatz von Google Analytics ist § 15 Abs. 3 TMG in Verbindung mit
              Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <p>
              Auch werden diese Informationen gegebenenfalls an Dritte
              übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit
              Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall
              Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die
              IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht
              möglich ist (sogenanntes IP-Masking).
            </p>
            <p>
              Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B.
              User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten
              automatisch gelöscht. Die Löschung von Daten, deren
              Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im
              Monat.
            </p>
            <p>
              Nähere Informationen zu Nutzungsbedingungen und Datenschutz von
              Google Analytics finden Sie unter
              marketingplatform.google.com/about/&shy;analytics/terms/de/.
            </p>
            <p>
              Sie können die Installation und Speicherung von Cookies durch eine
              entsprechende Einstellung Ihrer Browser-Software verhindern; wir
              weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
              nicht sämtliche Funktionen dieser Webseite vollumfänglich werden
              nutzen können.
            </p>
            <p>
              Sie können darüber hinaus die Erfassung der durch das Cookie
              erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl.
              Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google
              verhindern, indem Sie indem sie das unter dem folgenden Link
              verfügbare Browser-Plugin herunterladen und installieren:
              tools.google.com/dlpage/gaoptout?hl=de.
            </p>
            <p>
              Alternativ zum Browser-Add-on, insbesondere bei Browsern auf
              mobilen Endgeräten, können Sie die Erfassung durch Google
              Analytics zudem verhindern, indem Sie auf folgenden Link klicken.
              Es wird ein Opt-Out-Cookie gesetzt, der die zukünftige Erfassung
              Ihrer Daten beim Besuch dieser Webseite verhindert: Google
              Analytics deaktivieren. Der Opt-out-Cookie gilt nur in diesem
              Browser und nur für unsere Webseite und wird auf Ihrem Gerät
              abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie
              das Opt-out-Cookie erneut setzen.
            </p>
            <h3 className="h5">Google Adwords / Conversion Tracking</h3>
            <p>
              Wir nutzen zudem das Online-Werbeprogramm „Google AdWords“ im
              Rahmen von Google AdWords das Conversion-Tracking. Das Google
              Conversion Tracking ist ein von Google zur Verfügung gestellter
              Analysedienst. Wenn Sie auf eine von Google geschaltete Anzeige
              klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem
              Endgerät abgelegt. Diese Cookies verlieren nach 30 Tagen ihre
              Gültigkeit, enthalten keine personenbezogenen Daten und dienen
              somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte
              Internetseiten unserer Webseite besuchen und das Cookie noch nicht
              abgelaufen ist, können Google und wir erkennen, dass Sie auf die
              Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden.
              Jeder Google AdWords-Kunde erhält ein anderes Cookie. Somit
              besteht keine Möglichkeit, dass Cookies über Webseiten von
              AdWords-Kunden nachverfolgt werden können. Die Informationen, die
              mithilfe des Conversion-Cookie eingeholten werden, dienen dazu,
              Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich
              für Conversion-Tracking entschieden haben. Hierbei erfahren die
              Kunden die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt
              haben und zu einer mit einem Conversion-Tracking-Tag versehenen
              Seite weitergeleitet wurden. Sie erhalten jedoch keine
              Informationen, mit denen sich Nutzer persönlich identifizieren
              lassen. In diesen Zwecken liegt auch unser berechtigtes Interesse
              an der Datenverarbeitung. Die Rechtsgrundlage für den Einsatz von
              Google Adwords/ Conversion Tracking ist § 15 Abs. 3 TMG in
              Verbindung mit Art. 6 Abs. 1 lit. f DSGVO.
            </p>
            <p>
              Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser
              Nutzung widersprechen, indem Sie die Installation der Cookies von
              der Domain „googleadservices.com“ durch eine entsprechende
              Einstellung Ihrer Browser Software verhindern
              (Deaktivierungsmöglichkeit). Sie werden sodann nicht in die
              Conversion-Tracking Statistiken aufgenommen. Weiterführende
              Informationen sowie die Datenschutzerklärung von Google finden Sie
              unter: policies.google.com/technologies/ads ,
              policies.google.com/privacy.
            </p>
            <h3 className="h5">Google Adsense</h3>
            <p>
              Diese Webseite benutzt Google AdSense, einen Dienst zum Einbinden
              von Werbeanzeigen der Google Inc. ("Google"). Google AdSense
              verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer
              gespeichert werden und die eine Analyse der Benutzung der Webseite
              ermöglicht. Google AdSense verwendet auch so genannte Web Beacons
              (unsichtbare Grafiken). Durch diese Web Beacons können
              Informationen wie der Besucherverkehr auf diesen Seiten
              ausgewertet werden. Die durch Cookies und Web Beacons erzeugten
              Informationen über die Benutzung dieser Webseite (einschließlich
              Ihrer IP-Adresse) und Auslieferung von Werbeformaten werden an
              einen Server von Google in den USA übertragen und dort
              gespeichert. Diese Informationen können von Google an
              Vertragspartner von Google weiter gegeben werden. Google wird Ihre
              IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten
              zusammenführen. Sie können die Installation der Cookies durch eine
              entsprechende Einstellung Ihrer Browser Software verhindern; wir
              weisen Sie jedoch darauf hin, dass Sie in diesem Fall
              gegebenenfalls nicht sämtliche Funktionen dieser Webseite voll
              umfänglich nutzen können. Durch die Nutzung dieser Webseite
              erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten
              durch Google in der zuvor beschriebenen Art und Weise und zu dem
              zuvor benannten Zweck einverstanden.
            </p>
            <h3 className="h5">Google Remarketing</h3>
            <p>
              Diese Webseite benutzt Google Remarketing, einen Werbedienst der
              Google Inc. ("Google"). Drittanbieter, einschließlich Google,
              schalten Anzeigen auf Webseiten im Internet. Drittanbieter,
              einschließlich Google, verwenden Cookies zum Schalten von Anzeigen
              auf Grundlage vorheriger Besuche eines Nutzers auf dieser
              Webseite. Nutzer können die Verwendung von Cookies durch Google
              deaktivieren, indem sie die Seite zur Deaktivierung von
              Google-Werbung aufrufen www.google.com/privacy_ads.html.
              Alternativ können Nutzer die Verwendung von Cookies durch
              Drittanbieter deaktivieren, indem sie die Deaktivierungsseite der
              Netzwerkwerbeinitiative aufrufen
              www.networkadvertising.org/&shy;managing/opt_out.asp.
            </p>
            <h2 className="h4">8. Datensicherheit</h2>
            <p>
              Wir sind um alle notwendigen technischen und organisatorischen
              Sicherheitsmaßnahmen bemüht, um Ihre personenbezogenen Daten so zu
              speichern, dass sie weder Dritten noch der Öffentlichkeit
              zugänglich sind. Sollten Sie mit uns per E-Mail in Kontakt treten
              wollen, so weisen wir Sie darauf hin, dass bei diesem
              Kommunikationsweg die Vertraulichkeit der übermittelten
              Informationen nicht vollständig gewährleistet werden kann. Wir
              empfehlen Ihnen daher, uns vertrauliche Informationen
              ausschließlich über den Postweg zukommen zu lassen.
            </p>
            <h2 className="h4">
              9. Aktualität und Änderung dieser Datenschutzerklärung
            </h2>
            <p>Diese Datenschutzerklärung ist aktuell gültig.</p>
            <p>
              Durch die Weiterentwicklung unserer Webseite und Angebote darüber
              oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher
              Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu
              ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
              auf der Webseite unter www.monday-consulting.com/datenschutz von
              Ihnen abgerufen und ausgedruckt werden.
            </p>
            <h3 className="h5">
              Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            </h3>
            <p>
              Diese Datenschutz-Information gilt für die Datenverarbeitung
              durch:
            </p>
            <p>
              Monday Consulting GmbH
              <br />
              Breite Straße 61
              <br />
              22767 Hamburg
              <br />
              Telefon: +49 40 22 86 842 0<br />
              E-Mail:{" "}
              <a href="mailto:contact@monday-consulting.com">
                contact@monday-consulting.com
              </a>
              <br />
            </p>
            <h3 className="h5">Datenschutzbeauftragter:</h3>
            <p>
              D&C Datenschutz und Consulting
              <br />
              Dirk Borbe
              <br />
              Belemannweg 15
              <br />
              22419 Hamburg
              <br />
              Telefon: +49 162 58 17 253
              <br />
              E-Mail:{" "}
              <a href="mailto:datenschutz@monday-consulting.com">
                datenschutz@monday-consulting.com
              </a>
            </p>
            <p>Stand: 01.04.2021</p>
          </div>
        </div>
      </div>
      <ContactPerson
        headline="Datenschutz liegt uns am Herzen."
        text="Sollten Sie Fragen zu unserem Umgang mit dem Themen Datenschutz haben, zögern Sie nicht uns zu kontaktieren."
        contactEmail="datenschutz@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Volker Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.volker.childImageSharp.gatsbyImageData}
          alt="Volker Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Datenschutz;
